import { useCallback, useContext, useEffect } from 'react'
import { ActionType, storeContext } from '../../store'
import { Manager } from 'socket.io-client'

const baseURL = process.env.REACT_APP_SERVER_BASE_URL || ''

const SocketManager = () => {
  const { online, signedIn, accessToken, dispatch, socket } =
    useContext(storeContext)
  const createSocket = useCallback(() => {
    if (signedIn && online && accessToken) {
      if (!socket) {
        const manager = new Manager(baseURL)
        const socket = manager.socket('/', {
          auth: {
            accessToken,
          },
        })
        socket.on('connect_error', (error: any) => {
          if (error && error.message && error.message === 'TOKEN_EXPIRED') {
            console.error('Token expired, waiting to reconnect')
          } else {
            console.log(error)
          }
        })
        socket.on('connect', () => {
          console.log('CONNECTED')
          dispatch({ type: ActionType.CONNECT })
        })
        socket.on('disconnect', () => {
          console.log('DISCONNECTED')
          dispatch({ type: ActionType.DISCONNECT })
        })
        socket.on('data', ({ name }: { name: string }) => {
          dispatch({ type: ActionType.SET_NAME, name })
        })
        dispatch({ type: ActionType.SET_SOCKET, socket })
      }
    } else {
      if (socket) {
        dispatch({ type: ActionType.CLEAR_SOCKET })
        socket.disconnect()
      }
    }
  }, [signedIn, online, dispatch, socket, accessToken])

  useEffect(() => createSocket(), [createSocket])

  return <></>
}
export default SocketManager
