const baseURL = process.env.REACT_APP_SERVER_BASE_URL || ''

const getInitialAuth = async (googleAuthToken: string) => {
  let response: { accessToken: string; refreshToken: string } | undefined
  try {
    const apiResponse = await fetch(`${baseURL}/auth`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${googleAuthToken}`,
      },
    })
    if (apiResponse.ok) {
      response = await apiResponse.json()
    } else {
      console.error(await apiResponse.text())
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message)
    }
  }
  return response
}

const refreshAuth = async (refreshToken: string) => {
  let response: { accessToken: string; refreshToken: string } | undefined
  try {
    const apiResponse = await fetch(`${baseURL}/refresh`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${refreshToken}`,
      },
    })
    if (apiResponse.ok) {
      response = await apiResponse.json()
    } else {
      console.error(await apiResponse.text())
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message)
    }
  }
  return response
}

const removeAuth = async (refreshToken: string) => {
  try {
    const apiResponse = await fetch(`${baseURL}/logout`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${refreshToken}`,
      },
    })
    if (!apiResponse.ok) {
      console.error(await apiResponse.text())
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message)
    }
  }
}

export { getInitialAuth, refreshAuth, removeAuth }
