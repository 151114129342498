import { useCallback, useContext, useEffect } from 'react'
import { storeContext, ActionType } from '../../store'

const Online = () => {
  const { dispatch } = useContext(storeContext)
  const onlineEvent = useCallback(() => {
    dispatch({ type: ActionType.GO_ONLINE })
  }, [dispatch])
  const offlineEvent = useCallback(() => {
    dispatch({ type: ActionType.GO_OFFLINE })
  }, [dispatch])
  useEffect(() => {
    window.addEventListener('online', onlineEvent)
    window.addEventListener('offline', offlineEvent)
    return () => {
      window.removeEventListener('offline', offlineEvent)
      window.removeEventListener('online', onlineEvent)
    }
  }, [offlineEvent, onlineEvent])
  return <></>
}
export default Online
