import { useEffect, useContext, useState } from 'react'
import { removeAuth } from '../api/auth'

import { storeContext, ActionType } from '../store'

import edit from '../styles/icons/edit.svg'
import check from '../styles/icons/check.svg'

const Dashboard = () => {
  const { online, dispatch, refreshToken, name, socket } =
    useContext(storeContext)
  const [editing, setEditing] = useState(false)
  const [nameEdit, setNameEdit] = useState(name)
  useEffect(() => {
    document.title = 'Assassin'
  })
  return (
    <div className="modal">
      {!online && <p>! OFFLINE MODE !</p>}
      <p>
        your name:{' '}
        {editing ? (
          <>
            <input
              type="text"
              value={nameEdit}
              onChange={(e) => {
                setNameEdit(e.target.value)
              }}
              autoFocus
            />
            <img
              src={check}
              alt="Save"
              className="button"
              onClick={() => {
                if (socket) {
                  socket.emit('setName', nameEdit)
                  setEditing(false)
                }
              }}
            />
          </>
        ) : (
          <>
            {name}
            <img
              src={edit}
              alt="Edit"
              className="button"
              onClick={() => {
                setNameEdit(name)
                setEditing(true)
              }}
            />
          </>
        )}
      </p>
      {online && (
        <div
          className="button"
          onClick={() => {
            if (socket) {
              socket.emit('setName', 'julius')
            }
          }}
        >
          Create New Game
        </div>
      )}

      <div
        className="button"
        onClick={() => {
          if (refreshToken) {
            removeAuth(refreshToken)
          }
          dispatch({ type: ActionType.SIGN_OUT })
        }}
      >
        Sign Out
      </div>
    </div>
  )
}

export default Dashboard
