import { useContext, useEffect } from 'react'
import { ActionType, storeContext } from '../../store'
import { refreshAuth } from '../../api/auth'
import jwtDecode, { JwtPayload } from 'jwt-decode'

const TokenManager = () => {
  const { signedIn, accessToken, refreshToken, online, dispatch } =
    useContext(storeContext)

  useEffect(() => {
    if (signedIn && online) {
      if (!accessToken || !refreshToken) {
        dispatch({ type: ActionType.SIGN_OUT })
      } else {
        const refreshTokenPayload = jwtDecode<JwtPayload>(refreshToken)

        if (
          !refreshTokenPayload.exp ||
          Date.now() >= refreshTokenPayload.exp * 1000
        ) {
          dispatch({ type: ActionType.SIGN_OUT })
        } else {
          const accessTokenPayload = jwtDecode<JwtPayload>(accessToken)
          const checkAccessToken = async () => {
            console.log('it ran')
            if (
              !accessTokenPayload.exp ||
              Date.now() + 600000 >= accessTokenPayload.exp * 1000
            ) {
              const response = await refreshAuth(refreshToken)
              if (response) {
                dispatch({
                  type: ActionType.SIGN_IN,
                  accessToken: response.accessToken,
                  refreshToken: response.refreshToken,
                })
              } else {
                dispatch({ type: ActionType.SIGN_OUT })
              }
            }
          }
          const checkAccessTokenInterval = setInterval(checkAccessToken, 60000)
          checkAccessToken()
          return () => {
            clearInterval(checkAccessTokenInterval)
          }
        }
      }
    }
  }, [signedIn, online, accessToken, refreshToken, dispatch])

  return <></>
}
export default TokenManager
