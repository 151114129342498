import { useContext } from 'react'
import { storeContext } from '../store'
import { Redirect, Route, RouteProps } from 'react-router-dom'

const Loading = () => {
  return (
    <div className="modal">
      <h1>Loading</h1>
      <p className="fadein">
        If this message doesn't disappear, try reloading the page
      </p>
      <div className="loadingBar"></div>
    </div>
  )
}

const PrivateRoute = ({ children, ...props }: RouteProps) => {
  const { signedIn, online, connected } = useContext(storeContext)
  return (
    <Route {...props}>
      {signedIn ? (
        online && !connected ? (
          <Loading />
        ) : (
          children
        )
      ) : (
        <Redirect to="/" />
      )}
    </Route>
  )
}

const PublicRoute = ({ children, ...props }: RouteProps) => {
  const { signedIn, loading } = useContext(storeContext)
  return (
    <Route {...props}>
      {signedIn ? (
        <Redirect to="/dashboard" />
      ) : loading ? (
        <Loading />
      ) : (
        children
      )}
    </Route>
  )
}

export { PrivateRoute, PublicRoute }
