import { createContext, Dispatch } from 'react'
import { Socket } from 'socket.io-client'
import { getStoreData } from './utils/localStorage'

export type Store = {
  signedIn: boolean
  loading: boolean
  connected: boolean
  accessToken?: string
  refreshToken?: string
  socket?: Socket
  online: boolean
  googleOnline: boolean
  name?: string
}

export type Context = Store & { dispatch: Dispatch<Action> }

export enum ActionType {
  SIGN_IN,
  SIGN_OUT,
  GO_ONLINE,
  GO_OFFLINE,
  GOOGLE_ONLINE,
  SIGNING_IN,
  CONNECT,
  DISCONNECT,
  SET_SOCKET,
  CLEAR_SOCKET,
  SET_NAME,
}

export type Action = basicAction | signIn | setSocket | setName

type basicAction = {
  type:
    | ActionType.SIGN_OUT
    | ActionType.GO_ONLINE
    | ActionType.GO_OFFLINE
    | ActionType.GOOGLE_ONLINE
    | ActionType.SIGNING_IN
    | ActionType.CONNECT
    | ActionType.DISCONNECT
    | ActionType.CLEAR_SOCKET
}

type signIn = {
  type: ActionType.SIGN_IN
  accessToken: string
  refreshToken: string
}

type setSocket = {
  type: ActionType.SET_SOCKET
  socket: Socket
}

type setName = {
  type: ActionType.SET_NAME
  name: string
}

export const reducer = (store: Store, action: Action): Store => {
  switch (action.type) {
    case ActionType.SIGN_IN:
      return {
        ...store,
        loading: false,
        signedIn: true,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      }
    case ActionType.SET_NAME:
      return {
        ...store,
        name: action.name,
      }
    case ActionType.CONNECT:
      return {
        ...store,
        connected: true,
      }
    case ActionType.DISCONNECT:
      return {
        ...store,
        connected: false,
      }
    case ActionType.CLEAR_SOCKET:
      return {
        ...store,
        socket: undefined,
      }
    case ActionType.SET_SOCKET:
      return {
        ...store,
        socket: action.socket,
      }
    case ActionType.SIGNING_IN:
      return { ...store, signedIn: false, loading: true, name: 'Player' }
    case ActionType.SIGN_OUT:
      return { ...store, signedIn: false, loading: false, name: undefined }
    case ActionType.GO_ONLINE:
      return { ...store, online: true }
    case ActionType.GO_OFFLINE:
      return { ...store, online: false }
    case ActionType.GOOGLE_ONLINE:
      return { ...store, googleOnline: true }
    default:
      return store
  }
}

const initialStoreData = getStoreData()

export const initialStore: Store = {
  signedIn: initialStoreData.signedIn,
  online: navigator.onLine,
  googleOnline: false,
  connected: false,
  loading: false,
  accessToken: initialStoreData.accessToken,
  refreshToken: initialStoreData.refreshToken,
  name: initialStoreData.name,
}

export const storeContext = createContext<Context>({
  ...initialStore,
  dispatch: () => {},
})
