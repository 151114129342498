import { useEffect, useContext } from 'react'

import { storeContext } from '../store'

const Login = () => {
  const { online, googleOnline } = useContext(storeContext)
  useEffect(() => {
    document.title = 'Sign In'
  })
  return (
    <div className="modal">
      <h1>Assassin</h1>
      <p>an app for playing the game assassin</p>
      {online && googleOnline ? (
        <div
          ref={(ref) => {
            if (ref) {
              window.google.accounts.id.renderButton(ref, {
                theme: 'filled_black',
                size: 'large',
                shape: 'circle',
              })
            }
          }}
        ></div>
      ) : (
        <div>Please Go Online to Sign In.</div>
      )}
      <p className="faded">
        <span className="oneLine">
          created by <a href="https://trevorjex.com">trevor jex</a>.&nbsp;
        </span>
        <span className="oneLine">
          copyright &copy; {new Date().getFullYear()}. all rights reserved
        </span>
      </p>
      <p className="faded">
        <span className="oneLine">
          feel free to <a href="https://venmo.com/jextrevor">make a donation</a>
        </span>
      </p>
    </div>
  )
}

export default Login
