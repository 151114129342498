export const version0_1 = '0.1'

const currentVersion = version0_1

const itemName = 'assassin'

export type StoreData = {
  signedIn: boolean
  refreshToken?: string
  accessToken?: string
  name?: string
  version: string
}

const defaultData = {
  signedIn: false,
  version: currentVersion,
}

export const getStoreData = (): StoreData => {
  let data: StoreData = defaultData
  const unparsedData = localStorage.getItem(itemName)
  if (unparsedData) {
    try {
      data = JSON.parse(unparsedData)
      if (data && data.version && data.version !== currentVersion) {
        data = defaultData
      }
    } catch (error) {
      console.error(error)
    }
  }
  return data
}

export const writeStoreData = (data: StoreData) => {
  localStorage.setItem(itemName, JSON.stringify(data))
}
