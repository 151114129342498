import { useReducer } from 'react'

import { reducer, initialStore, storeContext } from './store'

import './styles/index.scss'

import GoogleSignIn from './components/helpers/GoogleSignIn'
import LocalStorage from './components/helpers/LocalStorage'
import Online from './components/helpers/Online'

import { BrowserRouter } from 'react-router-dom'
import { PrivateRoute, PublicRoute } from './views/routes'
import { Dashboard, Login } from './views'
import TokenManager from './components/helpers/TokenManager'
import SocketManager from './components/helpers/SocketManager'

const App = () => {
  const [store, dispatch] = useReducer(reducer, initialStore)

  return (
    <storeContext.Provider value={{ ...store, dispatch }}>
      <GoogleSignIn />
      <LocalStorage />
      <Online />
      <TokenManager />
      <SocketManager />
      <BrowserRouter>
        <PublicRoute exact path="/">
          <Login />
        </PublicRoute>
        <PrivateRoute exact path="/dashboard">
          <Dashboard />
        </PrivateRoute>
      </BrowserRouter>
    </storeContext.Provider>
  )
}

export default App
