import { useContext, useEffect } from 'react'
import { storeContext } from '../../store'
import { version0_1, writeStoreData } from '../../utils/localStorage'

const LocalStorage = () => {
  const { signedIn, accessToken, refreshToken, name } = useContext(storeContext)
  useEffect(() => {
    writeStoreData({
      signedIn,
      accessToken,
      refreshToken,
      name,
      version: version0_1,
    })
  }, [signedIn, accessToken, refreshToken, name])
  return <></>
}
export default LocalStorage
